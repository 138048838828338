<template>
  <article class="browser-notice-page">
    <main>
      <div v-if="isMobile">
        Please use desktop browser to enter the show.
      </div>
      <div v-else>
        We're sorry but this browser is not supported at the moment. <br>
        Please use <a href="https://www.google.com/chrome">Chrome</a> or <a href="https://www.microsoft.com/edge">Edge</a> browser. <br>
        Thanks!
      </div>

    </main>
  </article>
</template>
<script>

export default {
  data() {
    return {
      isMobile:  /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent),
    }
}
}
</script>

<style scoped lang="scss">
.browser-notice-page {
  display: grid;

  place-content: center;

  min-width: 100%;
  min-height: 100%;

  text-align: center;

  font-size: 2rem;
}
</style>
